@import "../Shared/variables.scss";

.navMenu {
  background: $sideBarColor;
  height: inherit;
  margin: 0;
  border: 0;
}

.navSubMenu {
  background: $sideBarColor;
  color: white;
  font-size: 1vw;
  font-weight: 500;
  :global {
    .ant-menu {
        background-color: $sideBarColor;
        color: white;
        font-weight: 400;
        font-size: 1vw;
    }
    .ant-menu-sub.ant-menu-inline > .ant-menu-item {
      line-height: 2vw;
      height: 2vw;
      margin: 0;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #ffffff0f;
    }
    .ant-menu-item:hover, .ant-menu-item-active {
      color: white;
    }
    .ant-menu-item-selected {
      color: white;
    }
    .ant-menu-item::after {
      border-right: 3px solid $accentColor;
    }
    .ant-menu-submenu-arrow {
      color: white;
    }
  }
}

/* Ipad Pro Tablet*/
@media screen and (max-width: 1024px) and (min-width: 769px) {
  .navSubMenu {
    background: $sideBarColor;
    color: white;
    font-size: 2vw;
    font-weight: 500;
    :global {
      .ant-menu {
          background-color: $sideBarColor;
          color: white;
          font-weight: 400;
          font-size: 2vw;
      }
      .ant-menu-sub.ant-menu-inline > .ant-menu-item {
        line-height: 5vw;
        height: 5vw;
        margin: 0;
      } 
    }
  }
}

/* Ipad Tablet*/
@media screen and (max-width: 768px) and (min-width: 601px) {
    .navSubMenu {
      background: $sideBarColor;
      color: white;
      font-size: 2vw;
      font-weight: 500;
      :global {
        .ant-menu {
            background-color: $sideBarColor;
            color: white;
            font-weight: 400;
            font-size: 2vw;
        }
        .ant-menu-sub.ant-menu-inline > .ant-menu-item {
          line-height: 5vw;
          height: 5vw;
          margin: 0;
        } 
      }
    }
  }
  
/* Mobiles*/
@media screen and (max-width: 600px) {
  .navSubMenu {
      background: $sideBarColor;
      color: white;
      font-size: 4vw;
      font-weight: 500;
      :global {
        .ant-menu {
            background-color: $sideBarColor;
            color: white;
            font-weight: 400;
            font-size: 4vw;
        }
        .ant-menu-sub.ant-menu-inline > .ant-menu-item {
          line-height: 8vw;
          height: 8vw;
          margin: 0;
        } 
      }
  }
}

// large screen resolutions 
@media screen and (min-width: 1900px) {
  .navSubMenu {
    font-size: 0.8vw;
    :global {
      .ant-menu {
          font-size: 0.8vw;
      }
      .ant-menu-sub.ant-menu-inline > .ant-menu-item {
        line-height: 2vw;
        height: 2vw;
        margin: 0;
      } 
    }
  }
}