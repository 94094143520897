.commentSection {
    text-align: left;
    font-size: 12px;
    padding: 5px;
}

.comment {
    font-size: 12px;
    line-height: 12px;
    font-weight: 700;
    padding: 5px;
    color: #229977;
}