//setting theme here
$primaryColor: #192125;
$secondaryColor: #263238;
$tertiaryColor: #151b1f;
$accentColor: #67d1e0;


//purple theme
// $primaryColor: #2b213a;
// $secondaryColor: #171520;
// $tertiaryColor: #21182d;
// $accentColor: #66fcf1;

$mainContentColor: $primaryColor;
$headerColor: $tertiaryColor;
$footerColor: #007acc;
// $footerColor: $tertiaryColor;
$sideBarColor: $secondaryColor;

$tabDefaultColor: #222c31;
$tabActiveColor: $primaryColor;
$tabNavColor: #1d262b;
$tabBottomBorderColor: $tertiaryColor;

$yellow: #feb029;
$cyan: #67d1e0;
$red: #e75c62;
$purple: #8e4c6f;
$blue: #36a2eb;
$green: #a7d129;