@import "../Shared/variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap');

.welcomeSection {
    text-align: left;
    width: 100%;
    color: white;
    padding: 0 24px;
}

.typist {
    font-size: 80px;
    line-height: 0.95;
    margin: 0;
    position: absolute;
    top: 40%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 50%;
}

.typistHello {
    color: $red;
    font-size: 5rem;
    font-weight: 800;
}

.typistName {
    color: $green;
    font-size: 4rem;
    font-weight: 800;
}

.typistProfession {
    color: $yellow;
    font-size: 3rem;
    font-weight: 700;
}

.profileBanner {
    height: 100vh;
    object-fit: contain;
    width: auto;
}

.profileBannerWrapper {
    float: right;
    width: 50%;
}

.typistWrapper {
    float: left;
    width: 50%;
}

.polaroidWrapper {
    width: auto;
    text-align: center;
    margin: 10%;
    top:20%;
    -ms-transform: translateY(-45%);
    transform: translateY(-45%);
    position: absolute;

}

.polaroid {
    background: #fff;
    padding: 1rem;
    box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);
}

.polaroidCaption {
    font-size: 1.8rem;
    text-align: center;
    line-height: 2em;
    color: black;
    font-family: 'Shadows Into Light', cursive;
}

.item {
    display: inline-block;
    filter: grayscale(100%);
}

.item .polaroid:before {
    content: '';
    position: absolute;
    z-index: -1;
    transition: all 0.35s;
}

.item:nth-of-type(4n+1) {
    transform: scale(0.8, 0.8) rotate(5deg);
    transition: all 0.35s;
}

.item:nth-of-type(4n+1) .polaroid:before {
    transform: rotate(6deg);
    height: 20%;
    width: 47%;
    bottom: 30px;
    right: 12px;
    box-shadow: 0 2.1rem 2rem rgba(0,0,0,0.4);
}

.item:hover {
    filter: none;
    transform: scale(1, 1) rotate(0deg) !important;
    transition: all 0.35s;
}

.item:hover .polaroid:before {
    content: '';
    position: absolute;
    z-index: -1;
    transform: rotate(0deg);
    height: 90%;
    width: 90%;
    bottom: 0%;
    right: 5%;
    box-shadow: 0 1rem 3rem rgba(0,0,0,0.2);
    transition: all 0.35s;
}

/* Common style for mobiles and tablets*/
@media screen and (max-width: 1024px) and (min-width: 250px){
    .welcomeSection {
        display: flex;
        flex-direction: column;
        margin-bottom: 60px;
    }
    .typistWrapper {
        flex: 100%;
        width: 100%;
        float:none;
    }
    .typist {
        font-size: 50px;
        line-height: 0.95;
        margin-top: 10%;
        position: relative;
        transform: none;
        -ms-transform: none;
        width: 100%;
    }
    .polaroidWrapper {
        width: 100%;
        text-align: center;
        position: relative;
        transform: none;
        -ms-transform: none;
        width: 100%;
        margin: 0;
        top: 0;
    }

    .profileBannerWrapper {
        flex:100%;
        width: 100%;
        float:none;    
    }
}

/* Mobiles*/
@media screen and (max-width: 600px) {
    .typistHello {
        font-size: 4rem;
    }
    .typistName {
        font-size: 2.5rem;
    }
    .polaroidWrapper {
        padding: 10% 15%;
    }
    .typistProfession {
        font-size: 2rem;
    }
}

/* Ipad*/
@media screen and (max-width: 768px) and (min-width: 601px) {
    .typistHello {
        font-size: 5rem;
    }
    .typistName {
        font-size: 3rem;
    }
    .polaroidWrapper {
        padding: 10% 20%;
    }
    .typistProfession {
        font-size: 2.5rem;
    }
}

/* Ipad*/
@media screen and (max-width: 1024px) and (min-width: 769px) {
    .typistHello {
        font-size: 6rem;
    }
    .typistName {
        font-size: 5rem;
    }
    .polaroidWrapper {
        padding: 10% 30%;
    }
    .typistProfession {
        font-size: 4rem;
    }
}

