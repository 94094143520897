@import "../../Shared/variables.scss";


.hidden {
    margin-left: 999em;
    height: 100vh;
}

.skillsSection {
    margin: 0 15%;
    height: 100vh;
    margin-bottom: 20%;
    min-height: 50px;
}

.skillsSectionTitle {
    color: white;
    font-size: 4vw;
    font-weight: 800;
}

.skillsLegendTitle {
    color: $red;
    font-size: 1vw;
    font-weight: 800;
}

.skillsLegendSection {
    margin-top: 40px;
    :global {
        .ant-rate-text {
            display: inline-block;
            margin: 0 8px;
            font-weight: 600;
            font-size: 0.8vw;
            color: $yellow;
        }
    }
}

.noviceSkills {
    font-size: 1vw;
    color: $purple;
}

.intermediateSkills {
    font-size: 2vw;
    color: $blue;
}

.expertSkills {
    font-size: 3vw;
    color: $cyan;
}
.expertStar {
    color: $cyan;
}
.noviceStar {
    color: $purple;
}
.intermediateStar {
    color: $blue;
}
.intermediateSkills, .expertSkills, .noviceSkills {
    margin: 0 5px;
    display: inline-block;
    padding: 0;
    cursor: pointer;
    -webkit-animation: upDownAnimation 1.5s infinite  alternate;
    animation: upDownAnimation 1.5s infinite  alternate;
    @for $i from 1 through 50 {
        &:nth-child(#{$i}) {
          animation-delay: 0.5s * $i;
        }
    }
}

@-webkit-keyframes upDownAnimation {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@keyframes upDownAnimation {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}


/* Mobiles*/
@media screen and (max-width: 600px) { 
    .skillsSection {
        margin: 0 10%;
        margin-bottom: 100%;
    }
    .noviceSkills {
        font-size: 3vw;
    }
    
    .intermediateSkills {
        font-size: 4vw;
    }
    
    .expertSkills {
        font-size: 6vw;
    }

    .skillsLegendTitle {
        font-size: 4vw;
    }

    .skillsLegendSection {
        :global {
            .ant-rate-text {
                font-size: 3vw;
            }
        }
    }
}

/* Ipad Tablet*/
@media screen and (max-width: 768px) and (min-width: 601px) {
    .skillsSection {
        margin: 0 10%;
    }
    .noviceSkills {
        font-size: 2vw;
    }
    
    .intermediateSkills {
        font-size: 3vw;
    }
    
    .expertSkills {
        font-size: 5vw;
    }

    .skillsLegendTitle {
        font-size: 3vw;
    }

    .skillsLegendSection {
        :global {
            .ant-rate-text {
                font-size: 2vw;
            }
        }
    }
}

/* Ipad Pro Tablet*/
@media screen and (max-width: 1024px) and (min-width: 769px) { 
    .skillsSection {
        margin: 0 10%;
    }
    .noviceSkills {
        font-size: 2vw;
    }
    
    .intermediateSkills {
        font-size: 3vw;
    }
    
    .expertSkills {
        font-size: 5vw;
    }

    .skillsLegendTitle {
        font-size: 3vw;
    }

    .skillsLegendSection {
        :global {
            .ant-rate-text {
                font-size: 2vw;
            }
        }
    }
}

// large screen resolutions 
@media screen and (min-width: 1900px) {
    .noviceSkills {
        font-size: 1.5vw;
    }
    
    .intermediateSkills {
        font-size: 2vw;
    }
    
    .expertSkills {
        font-size: 3vw;
    }
}