@import "../../Shared/variables.scss";

.connectSection {
    :global {
        a.ant-btn {
            padding-top: 4px;
        }
        .ant-btn:hover, .ant-btn:focus, .ant-btn:active {
            background-color: #0072b1;
        }
        .react-reveal {
            float: left;
        }
    }
}

.hidden {opacity: 0;}
.connectionContentWrapper {
    display: flex;
    margin: auto;
    height: 100vh;
    margin-bottom: 50%;
}

.connectdpWrapper {
    flex: 1;
    margin: auto;
    text-align: end;
    padding: 10vh;
}

.connectTextWrapper {
    flex: 2;
    margin: auto;
    text-align: start;
    padding-right: 5vw;
}

.connectdp {
    width: 100%;
    height: auto;
    border-radius: 30px;
    text-align: right;
    max-width: 400px;
}

.connectTitle {
    font-weight: 800;
    color: $purple;
    font-size: 4vw;
}

.connectText {
    font-weight: 400;
    color: white;
    font-size: 1vw;
    text-align: left;
    float: left;
}

.friendAvatar {
    padding: 10px;
    width: 100px;
}

.connectButton {
    font-weight: 500;
    font-size: 1.5vw;
    height: 40px;
    background-color: #0072b1;
    color: white;
    border: 0;
    padding: 20px;
    margin-top: 20px;
}

.resumeButton {
    font-weight: 500;
    font-size: 1.5vw;
    height: 40px;
    background-color: #67d1e0;
    color: #192125;
    border: 0;
    padding: 20px;
    margin-top: 20px;
}

.attribution {
    color: #ffffff66;
    margin-top: 20px;
    font-size: 0.5vw;
    width: -webkit-fill-available;
}

/* Mobile and Tablets */
@media screen and (max-width: 1024px) {
    .connectSection {
        margin-bottom: 100%;
        :global {
            .react-reveal {
                display: inline-block;
                float: none;
            }
        }
    }

    .connectdp {
        text-align: center;
    }
    .connectText {
        float: none;
    }

    .connectionContentWrapper {
        display: inherit;
        margin: 0 10%;
    }
}


@media screen and (max-width: 320px) {  
    .connectSection {
        margin-bottom: 80px;
    }
}
/* Mobiles*/
@media screen and (max-width: 600px) {  
    .connectdp {
        max-width: 200px;
        margin-top: 20px;
    }
    .connectText {
        font-size: 3vw;
    }
    .friendAvatar {
        padding: 5px;
        width: 50px;
    }
    .connectButton, .resumeButton {
        font-size: 4vw;
        height: 40px;
        padding: 20px;
        margin-top: 40px;
    }

    .attribution {
        margin-top: 10px;
        font-size: 2vw;
    }
}

/* Ipad Tablet*/
@media screen and (max-width: 768px) and (min-width: 601px) { 
    .connectSection {
        margin-bottom: 50%;
    }
    .connectdp {
        max-width: 300px;
        margin-top: 40px;
    }
    .connectText {
        font-size: 2vw;
    }
    .friendAvatar {
        padding: 5px;
        width: 70px;
    }
    .connectButton, .resumeButton {
        font-size: 2.5vw;
        height: 40px;
        padding: 20px;
        margin-top: 40px;
    }
    .attribution {
        margin-top: 10px;
        font-size: 2vw;
    }
}

/* Ipad Pro Tablet*/
@media screen and (max-width: 1024px) and (min-width: 769px) {
    .connectSection {
        margin-bottom: 50%;
    }
    .connectdp {
        max-width: 400px;
        margin-top: 40px;
    }
    .connectText {
        font-size: 2.5vw;
    }
    .friendAvatar {
        padding: 10px;
        width: 100px;
    }
    .connectButton, .resumeButton {
        font-size: 2vw;
        height: 40px;
        padding: 20px;
        margin-top: 40px;
    }
    .attribution {
        margin-top: 10px;
        font-size: 2vw;
    }   
}

// large screen resolutions 
@media screen and (min-width: 1900px) {
    .connectButton, .resumeButton {
        font-size: 1vw;
    }
}   