@import "../Shared/variables.scss";

.mainContentLayout {
  background: $mainContentColor;
  padding: 0;
  height: inherit;
}
.mainContent {
  padding: 0;
  height: inherit;
  position: fixed;
  left: 0;
  right: 0;
  top: 40px;
  overflow: auto;
}


@media screen and (max-width: 1024px) { 
  .mainContent {
    position: unset;
    overflow: hidden;
  }
}


