@import "../../Shared/variables.scss";

.projectSectionTitle {
    color: white;
    font-size: 4vw;
    font-weight: 800;
}


.hidden {opacity: 0;}

.project, .projectThemeRed, .projectThemeBlue, .projectThemeYellow, .projectThemePurple, .projectThemeCyan {
    margin-top: 30px;
    width: inherit;
    padding: 20px;
    border-radius: 20px;
}

.projectTextContent, .projectImageContent{
    flex: 0 0 50%;
    margin: auto;
    text-align: left;
    padding: 10px;
}

.projectImageContent {
    text-align: center;
}

.projectWrapper {
    display: flex;
    flex-wrap: wrap;
}

.projectSection {
    height: inherit;
    margin-bottom: 50%;
    min-height: 50px;
    padding: 0 10%;
}

.projectThemeRed {
    background-color: $red;
}

.projectThemePurple {
    background-color: $purple;
}

.projectThemeYellow {
    background-color: $yellow;
}

.projectThemeBlue {
    background-color: $blue;
}

.projectThemeCyan {
    background-color: $cyan;
}

.projectName {
    font-size: 2vw;
    color: white;
    font-weight: 700;
}

.moreProjectsPlaceholder {
    margin: 10px;
    font-size: 1vw;
    color: $yellow;
    font-weight: 500;
}

.projectImage {
    padding: 10px;
    width: 100%;
    height: auto;
    border-radius: 20px;
}

.projectDescription {
    color: whitesmoke;
    font-size: 1vw;
    font-weight: 500;
}

/* Mobiles*/
@media screen and (max-width: 600px) { 
    .projectSection {
        padding: 0 5%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-bottom: 100%;
    }
    :global {
        
        .react-reveal {
            flex: 0 1 100%;
            display: flex;
            flex-direction: column;
        }
    }
    .projectName {
        font-size: 4vw;
    }
    .projectDescription {
        font-size: 3vw;
    }

    .projectImage {
        padding: 5px;
    }

    .moreProjectsPlaceholder {
        font-size: 3vw;
    }    
}

/* Ipad Tablet*/
@media screen and (max-width: 768px) and (min-width: 601px) {
    .projectSection {
        padding: 0 10%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-bottom: 100%;
    }
    :global {
        
        .react-reveal {
            flex: 0 1 100%;
            display: flex;
            flex-direction: column;
        }
    }
    .projectName {
        font-size: 3vw;
    }
    .projectDescription {
        font-size: 2vw;
    }

    .projectImage {
        padding: 10px;
    }

    .moreProjectsPlaceholder {
        font-size: 3vw;
    } 
}

/* Ipad Pro Tablet*/
@media screen and (max-width: 1024px) and (min-width: 769px) { 
    .projectSection {
        padding: 0 10%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-bottom: 100%;
    }
    :global {
        
        .react-reveal {
            flex: 0 1 100%;
            display: flex;
            flex-direction: column;
        }
    }
    .projectName {
        font-size: 3vw;
    }
    .projectDescription {
        font-size: 2vw;
    }
    .projectImage {
        padding: 10px;
    }
    .moreProjectsPlaceholder {
        font-size: 2vw;
    } 
}

// large screen resolutions 
@media screen and (min-width: 1900px) {
    .projectSection {
        padding: 0 10%;
        display: table;
        min-height: 50px;
    }
    .projectName {
        font-size: 1.5vw;
    }
    .projectDescription {
        font-size: 1vw;
    }
    .projectImage {
        max-width: 500px;
    }
    .moreProjectsPlaceholder {
        font-size: 1vw;
    } 
}