@import "../Shared/variables.scss";

.navDrawer {
    position: fixed;
    :global { 
        .ant-drawer-body {
            padding: 0;
            background: $sideBarColor;
        }
    }
}

.navDrawerFooter {
    bottom:0;
    padding-bottom: 50px;
    width: 256px;
    position: fixed;
    text-align: center;
    font-weight: 400;
}

.navDrawerFooterText {
    color: white;
}