@import "../../Shared/variables.scss";

.interestSection {
    height: inherit;
    margin-bottom: 20%;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    :global {
        .ant-tag {
            margin: 5px;
        }
        .react-reveal {
            flex: 0 1 30%;
            display: flex;
            flex-direction: column;
        }
        .ant-card-cover img {
            border-radius: 10px 10px 0px 0px;
        }
    }
}

.hidden {opacity: 0;}

.bingeWatching {
    background-color: $yellow;
}

.gaming {
    background-color: $cyan;
}

.travelling {
    background-color: $blue;
}

.foodie {
    background-color: $red;
}

.realmadrid {
    background-color: $purple;
}
.bingeWatching, .foodie, .gaming, .realmadrid, .travelling {
    margin: 20px;
    border: 0;
    border-radius: 10px;
}

.interestSectionTitle {
    color: white;
    font-size: 4vw;
    font-weight: 800;
}

.interestTitle {
    font-size: 2vw;
    color:white;
    font-weight: 800;
}

.interestTags {
    margin: 20px;
    font-weight: 600;
}

.interestGIF {
    height: auto;
}

/* Mobiles*/
@media screen and (max-width: 600px) { 
    .interestTitle {
        font-size: 4vw;
    }
    .interestSection {
        margin-bottom: 100%;
        :global {
            .ant-tag {
                margin: 5px;
            }
            .react-reveal {
                flex: 0 1 90%;
            }
        }
    }

    .interestTags {
        margin: 10px;
        font-size: 3vw;
        font-weight: 600;
    }
}

/* Ipad Tablet*/
@media screen and (max-width: 768px) and (min-width: 601px) {
    .interestTitle {
        font-size: 3vw;
    }

    .interestSection {
        margin-bottom: 100%;
        :global {
            .ant-tag {
                margin: 5px;
            }
            .react-reveal {
                flex: 0 1 50%;
            }
        }
    }

    .interestTags {
        margin: 10px;
        font-size: 2vw;
        font-weight: 600;
    }
}

/* Ipad Pro Tablet*/
@media screen and (max-width: 1024px) and (min-width: 769px) { 
    .interestTitle {
        font-size: 2.5vw;
    }

    .interestSection {
        margin-bottom: 100%;
        :global {
            .ant-tag {
                margin: 5px;
            }
            .react-reveal {
                flex: 0 1 40%;
            }
        }
    }

    .interestTags {
        margin: 10px;
        font-size: 1.5vw;
        font-weight: 600;
    }
}

// large screen resolutions 
@media screen and (min-width: 1900px) {
    .interestTitle {
        font-size: 1vw;
    }
    .interestSection {
        :global {
            .ant-tag {
                margin: 10px;
            }
            .react-reveal {
                flex: 0 1 26%;
            }
        }
    }
    .interestTags {
        margin: 20px;
        font-size: 0.8vw;
        font-weight: 600;
    }
}