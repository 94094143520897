.card {
    display: grid;
    text-align: center;
    padding: 10px;
}
.profileAvatar {
    margin: auto;
}
.profileName {
    font-size: 1.25rem;
    font-weight: 700;
    color: #2b2d42;
}
.profileTitle {
    font-size:1rem;
    font-weight: 500;
    color: #8d99ae;
}
.profileLocation {
    font-size: 0.75rem;
    font-weight: 400;
    color: #8d99ae;
}
.profileQoute {
    margin-top: 10px;
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    color: #8d99ae;
}

.profileButton {
    margin-top: 10px;
    font-size: 0.875rem;
}

.devName {
    color: white;
    margin-left: 10px;
}

.countryFlag {
    width: 20px;
    margin: 5px;
    height: auto;
}

/* Ipad Pro Tablet*/
@media screen and (max-width: 1024px) and (min-width: 769px) { 
    :global {
        .ant-tooltip {
            max-width: 400px;
        }
    }
    .profileAvatar {
        width: 100px;
        height: 100px;
    }
    .profileName {
        font-size: 2.5vw;
    }
    .profileTitle {
        font-size:2vw;
    }
    .profileLocation {
        font-size: 1.5vw;
    }
    .profileQoute {
        font-size: 1.5vw;
    }
    .profileButton {
        font-size: 1.5vw;
    }
  }

/* Ipad Tablet*/
@media screen and (max-width: 768px) and (min-width: 601px) { 
    :global {
        .ant-tooltip {
            max-width: 300px;
        }
    }
    .profileAvatar {
        width: 100px;
        height: 100px;
    }
    .profileName {
        font-size: 2.5vw;
    }
    .profileTitle {
        font-size:2vw;
    }
    .profileLocation {
        font-size: 1.5vw;
    }
    .profileQoute {
        font-size: 1.5vw;
    }
    .profileButton {
        font-size: 1.5vw;
    }
}

/* Mobiles*/
@media screen and (max-width: 600px) { 
    :global {
        .ant-tooltip {
            max-width: 250px;
        }
    }
    .profileAvatar {
        width: 100px;
        height: 100px;
    }
    .profileName {
        font-size: 4vw;
    }
    .profileTitle {
        font-size:3.5vw;
    }
    .profileLocation {
        font-size: 3vw;
    }
    .profileQoute {
        font-size: 3vw;
    }
    .profileButton {
        font-size: 3vw;
    }
}