@import "../Shared/variables.scss";
.footer {
  padding: 4px;
  color: white;
  left: 0;
  bottom: 0;
  width: 100%;
  position: fixed;
  background: $footerColor;
  height:30px; 
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5715;
}

.alignLeft {
  color: white;
  float: left;
  padding-left: 10px;
}

.alignRight {
  color: white;
  float: right;
  padding-right: 10px;
}

.alignCenter {
  color: white;
  text-align: center;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  display: inline-block;
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}