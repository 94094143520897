/* Hide scrollbar for Chrome, Safari and Opera */
.resumeSection::-webkit-scrollbar {
    display: none;
}
  
/* Hide scrollbar for IE, Edge and Firefox */
.resumeSection {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding: 0;
    min-height: 280px;
    overflow: auto;
}