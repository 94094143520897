@import "../Shared/variables.scss";

.sectiontabs {
    :global {
        .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
            background-color: $tabDefaultColor;
            border: 0;
        }
        .ant-tabs {
            color: white;
        }
        .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
            background: $tabActiveColor;
            color: $cyan;
            border-bottom: 1px solid $accentColor;
        }
        .ant-tabs-tab-remove {
            color: white;
            font-size: 14px;
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: $accentColor;
        }
        .ant-tabs-top > .ant-tabs-nav::before {
            border-bottom: 1px solid $tabBottomBorderColor;
        }
        .ant-tabs > .ant-tabs-nav {
            background-color: $tabNavColor;
        }
        .ant-tabs-top > .ant-tabs-nav {
            margin: 0;
        }
    }
}