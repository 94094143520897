.sectionHeader{
    color: white;
    font-size: 4vw;
    font-weight: 800;
}

/* Mobiles*/
@media screen and (max-width: 600px) { 
    .sectionHeader{
        font-size: 10vw;
    }
}

/* Ipad Tablet*/
@media screen and (max-width: 768px) and (min-width: 601px) {
    .sectionHeader{
        font-size: 8vw;
    }
}

/* Ipad Pro Tablet*/
@media screen and (max-width: 1024px) and (min-width: 769px) { 
    .sectionHeader{
        font-size: 8vw;
    }
}