@import "../../Shared/variables.scss";

.experienceSection {
    height: inherit;
    margin: 0 20%;
    margin-bottom: 20%;
    padding: 40px;
    :global {
        .ant-timeline-item-head {
            background-color: $primaryColor;
            font-size: 1.5vw;
        }
        .react-reveal {
            display: inline-block;
        }
    }
}


.hidden {
    opacity: 0;
}

.workExpCard, .workExpCardThemeRed, .workExpCardThemeBlue, .workExpCardThemePurple, .workExpCardThemeCyan, .workExpCardThemeYellow {
    padding: 20px;
    text-align: left;
    border-radius: 20px;
}

.workExpCardThemeRed {
    background-color: $red;
}
.workExpCardThemeBlue {
    background-color: $blue;
}
.workExpCardThemePurple {
    background-color: $purple;
}
.workExpCardThemeCyan {
    background-color: $cyan;
}
.workExpCardThemeYellow {
    background-color: $yellow;
}

.workExpStart {
    text-align: left;
}

.workExpStart {
    text-align: left;
}

.companyName {
    font-size: 1vw;
    color: white;
    font-weight: 500;
}

.jobTitle {
    font-size: 1.5vw;
    color: white;
    font-weight: 600;
}

.jobPeriod {
    padding: 5px;
    font-size: 0.8vw;
    color: white;
    font-weight: 700;
}

.workDescription {
    font-size: 1vw;
    color: white;
    font-weight: 500;
}

.techStackIcon {
    font-size: 2vw;
    height: 2vw;
    width: auto;
}

.techStackSection {
    font-size: 2vw;
    height: 2vw;
}

/* Mobiles*/
@media screen and (max-width: 600px) { 
    .experienceSection { 
        margin: 0 5%;
        margin-bottom: 100%;
        padding: 5%;
    
        :global {
            .ant-timeline-item-head {
                font-size: 5vw;
            }
            .ant-tag {
                margin: 0;
                padding: 0;
                line-height: 0;
            }
        }
    }
    .companyName {
        font-size: 3.5vw;
    }
    
    .jobTitle {
        font-size: 4vw;
        margin-top: 20px;
    }
    
    .jobPeriod {
        padding: 10px;
        font-size: 2.5vw;
        line-height: 5vw;
    }
    
    .workDescription {
        font-size: 3vw;
    }
    
    .techStackIcon {
        font-size: 5vw;
        height: 5vw;
    }
    
    .techStackSection {
        font-size: 5vw;
        height: 5vw;
    }  
}

/* Ipad Tablet*/
@media screen and (max-width: 768px) and (min-width: 601px) {
    .experienceSection { 
        margin: 0 5%;
        margin-bottom: 60px;
        padding: 5%;
    
        :global {
            .ant-timeline-item-head {
                font-size: 3vw;
            }
            .ant-tag {
                margin: 0;
                padding: 0;
                line-height: 0;
            }
        }
    }
    .companyName {
        font-size: 2.5vw;
    }
    
    .jobTitle {
        font-size: 3vw;
    }
    
    .jobPeriod {
        padding: 10px;
        font-size: 1.5vw;
        line-height: 3vw;
    }
    
    .workDescription {
        font-size: 2vw;
    }
    
    .techStackIcon {
        font-size: 3vw;
        height: 3vw;
    }
    
    .techStackSection {
        font-size: 3vw;
        height: 3vw;
    }  
}

/* Ipad Pro Tablet*/
@media screen and (max-width: 1024px) and (min-width: 769px) {
    .experienceSection { 
        margin: 0 10%;
        margin-bottom: 60px;
        padding: 5%;
    
        :global {
            .ant-timeline-item-head {
                font-size: 2vw;
            }
            .ant-tag {
                margin: 0;
                padding: 0;
                line-height: 0;
            }
        }
    }
    .companyName {
        font-size: 2vw;
    }
    
    .jobTitle {
        font-size: 2.5vw;
    }
    
    .jobPeriod {
        padding: 10px;
        font-size: 1.5vw;
        line-height: 3vw;
    }
    
    .workDescription {
        font-size: 1.5vw;
    }
    
    .techStackIcon {
        font-size: 2.5vw;
        height: 2.5vw;
    }
    
    .techStackSection {
        font-size: 2.5vw;
        height: 2.5vw;
    }  
}

// large screen resolutions 
@media screen and (min-width: 1900px) {
    .workDescription {
        font-size: .8vw; 
    }
}