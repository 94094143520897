@import "../../Shared/variables.scss";
.collegeName {
    font-size: 1.5rem;
    font-weight: 800;
    color:white
}

.collegeDegree {
    font-size: 1rem;
    font-weight: 600;
    color:white
}

.collegeGPA {
    font-size: 0.8rem; 
    font-weight: 500;
    color:white
}

.collegeCardBE {
    background-color: $purple;
    border: 0;
    text-align: left;
    width: auto;
}

.collegeCardMS {
    background-color: $blue;
    border: 0;
    text-align: left;
}