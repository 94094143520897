@import "../Shared/variables.scss";

.header {
    background: $headerColor;
    height:40px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 40px;
  }
  .alignLeft {
    text-align: left;
    float: left;
  }

  .alignRight {
    text-align: right;
    float: right;
  }

  .headerTitle {
    color: white;
    font-size: 12px;
    font-weight: 400;
  }

  @media screen and (max-width: 1024px) { 
    .header {
      height:50px;
      line-height: 50px;
    }
    .headerTitle {
      font-weight: 500;
    }
  }

/* Mobiles*/
@media screen and (max-width: 600px) { 
  .headerTitle {
    font-size: 4vw;
  }
}

/* Ipad Tablet*/
@media screen and (max-width: 768px) and (min-width: 601px) { 
  .headerTitle {
    font-size: 2vw;
  }
}

/* Ipad Pro Tablet*/
@media screen and (max-width: 1024px) and (min-width: 769px) { 
  .headerTitle {
    font-size: 2vw;
  }
}
  