@import "../../Shared/variables.scss";

.bachelorsIllustration {
    width: 300px;
}

.hidden {
    opacity: 0;
}

.mastersIllustration {
    width: 300px;
    margin-left: auto;
}

.onTableItems {
    display: flex;
    align-items: flex-end;
}

.books {
    width: 50%;
    margin-right: 5%;
}

.coffeemug{
    width: 30%;
    margin-right: 5%;
}

.laptop {
    width: 100%;
    margin-right: 5%;
}

.mastersIllustration {
    width: 300px;
}

.coffeeCup {
    width: 20%;
    margin-right: 5%;
}

.lamp {
    width: 50%;
    margin-right: 5%;
}

.monitor {
    width: 100%;
    margin-right: 5%;
}

.educationTitle {
    color: $red;
    font-size: 4vw;
    font-weight: 800;
}

.educationSection {
    flex: auto;
    :global {
        .ant-timeline {
            color: white;
            font-weight: 600;
        }
        .react-reveal {
            flex: auto;
        }
        .ant-timeline-item-head {
            background-color: $primaryColor;
        }
    }
}
.educationSectionWrapper {
    display: block;
    margin-bottom: 20%;
}

.masterDegreeCard {
    width: 300px;
    display: flex;
}

.bachelorsDegreeCard {
    width: 300px;
    display: flex;
    margin-left: auto;
}


/* Mobile and tablets*/
@media screen and (max-width: 1024px) {  
    .educationSection {
        display: inherit;
        flex: auto;
        margin: 0 10%;

        :global {
            .ant-timeline-item-content {
                text-align: left;
                font-size: 2vw;
                padding-top: 5px;
            }
            .ant-timeline {
                margin-top: 5%;
            }
            .ant-timeline-item-head {
                font-size: 3vw;
            }
        }
    }

    .educationSectionWrapper {
        display: inherit;
        margin-bottom: 50%;
    }

    .bachelorsDegreeCard {
        width: 100%;
        margin: 0;
        display: grid;
    }
    
    .masterDegreeCard {
        width: 100%;
        margin: 0;
        display: grid;
    }
}

/* Mobiles*/
@media screen and (max-width: 600px) {  
    .educationSection {
        margin: 0 10%;

        :global {
            .ant-timeline-item-content {
                font-size: 3vw;
            }
            .ant-timeline {
                margin-top: 5%;
            }
            .ant-timeline-item-head {
                font-size: 5vw;
            }
        }
    }

    .mastersIllustration {
        width: 200px;
        margin: 0;
    }
    .bachelorsIllustration {
        width: 200px;
        margin: 0;
    }
}

/* Ipad Tablet*/
@media screen and (max-width: 768px) and (min-width: 601px) { 
    .mastersIllustration {
        width: 300px;
        margin: 0;
    }
    .bachelorsIllustration {
        width: 300px;
        margin: 0;
    }
}

/* Ipad Pro Tablet*/
@media screen and (max-width: 1024px) and (min-width: 769px) {
    .mastersIllustration {
        width: 400px;
        margin: 0;
    }
    .bachelorsIllustration {
        width: 400px;
        margin: 0;
    }

    .educationSection {
     
        :global {
            .ant-timeline-item-content {
                text-align: left;
                font-size: 2vw;
                padding-top: 0px;
            }
            .ant-timeline-item-head {
                font-size: 2vw;
            }
        }
    }
}
